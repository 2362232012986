var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "en-loading",
          rawName: "v-en-loading",
          value: _vm.enLoading,
          expression: "enLoading",
        },
      ],
      staticClass: "mail-search-wrap",
    },
    [
      _c(
        "en-top-menu",
        { attrs: { type: "white", "need-back": "", "menu-name": "全局搜索" } },
        [
          _c(
            "div",
            { attrs: { slot: "right" }, slot: "right" },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    "popper-class": "mail-search-popover",
                    value: _vm.popShow,
                    placement: "bottom",
                    width: 430,
                    offset: 80,
                    trigger: "manual",
                  },
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "reference" }, slot: "reference" },
                    [
                      _c(
                        "el-input",
                        {
                          ref: "searchIpt",
                          attrs: {
                            autofocus: true,
                            clearable: true,
                            placeholder: "请输入",
                            size: "mini",
                          },
                          on: { focus: _vm.handleFocus },
                          model: {
                            value: _vm.txt,
                            callback: function ($$v) {
                              _vm.txt = $$v
                            },
                            expression: "txt",
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "append" },
                            [
                              _c("en-icon", {
                                attrs: {
                                  name: "sousuo",
                                  color: "#abb7c7",
                                  size: "mini",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c("ul", [
                    _c("li", { on: { click: _vm.handleKeySearch } }, [
                      _vm._v("包含 "),
                      _c("span", { staticStyle: { color: "#3e90fe" } }, [
                        _vm._v(_vm._s(_vm.txt)),
                      ]),
                      _vm._v(" 的邮件（全文搜索）"),
                    ]),
                    _c("li", { on: { click: _vm.handleAdvanceClick } }, [
                      _vm._v("高级搜索..."),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "mail-container" }, [
        _c(
          "div",
          { staticClass: "mail-list-wrap" },
          [
            _c(
              "en-title-card",
              {
                staticStyle: { overflow: "hidden" },
                attrs: { name: "搜索结果" },
              },
              [
                _c(
                  "template",
                  { slot: "right" },
                  [
                    _c("button-mod", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.mailList.length,
                          expression: "mailList.length",
                        },
                      ],
                      attrs: { mailType: 0 },
                    }),
                  ],
                  1
                ),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "mail-list-result" },
              [
                _c("en-result", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.mailList.length === 0,
                      expression: "mailList.length === 0",
                    },
                  ],
                  attrs: {
                    type: "NoCustom",
                    subTitle: "暂无搜索内容,换个搜索条件试试~",
                    imgPath: _vm.noDataImg,
                  },
                }),
                _vm._l(_vm.mailList, function (itm, index) {
                  return _c("mail-mod", {
                    key: itm.id,
                    attrs: {
                      index: index,
                      mailData: itm,
                      "checkbox-visible": _vm.mailList.some(
                        (item) => item.checked
                      ),
                      usedFlg: "search",
                    },
                  })
                }),
              ],
              2
            ),
            _c("en-pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.mailList.length,
                  expression: "mailList.length",
                },
              ],
              attrs: { pageModel: _vm.pageModel },
              on: { change: _vm.handlePageChanged },
            }),
          ],
          1
        ),
      ]),
      _c("mapping-dialog", {
        ref: "mappingDialog",
        attrs: {
          "mapping-list": _vm.mappingList,
          "relat-visible": _vm.relatVisible,
        },
        on: {
          closeDialog: function ($event) {
            _vm.relatVisible = false
          },
          handleRelatClick: _vm.handleRelatClick,
        },
      }),
      _vm.detailVisible
        ? _c("mail-detail", {
            ref: "mailDetail",
            attrs: {
              needNext: _vm.needNext,
              nextObj: _vm.nextObj,
              id: _vm.detailId,
              visible: _vm.detailVisible,
            },
            on: {
              close: function ($event) {
                _vm.detailVisible = false
              },
            },
          })
        : _vm._e(),
      _c("advance-search", {
        ref: "advanceMod",
        attrs: { "has-change-keyword": _vm.hasChangeKeyword },
      }),
      _vm.addMappingflg
        ? _c("Mapping", {
            attrs: {
              id: _vm.mappingIds,
              show: _vm.addMappingflg,
              mappingList: _vm.mappingList,
            },
            on: {
              toShow: function ($event) {
                _vm.addMappingflg = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }