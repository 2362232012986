<!--
 * @Author: pengyu
 * @Date: 2021-03-15 17:58:55
 * @LastEditors: pengyu
 * @LastEditTime: 2021-04-25 16:56:27
 * @Description: 邮件全局搜索列表
-->
<template>
  <div class="mail-search-wrap" v-en-loading="enLoading">
    <en-top-menu type="white" need-back menu-name="全局搜索">
      <div slot="right">
        <el-popover popper-class="mail-search-popover" :value="popShow" placement="bottom" :width="430" :offset="80" trigger="manual">
          <div slot="reference">
            <el-input ref="searchIpt" :autofocus="true" :clearable="true" placeholder="请输入" v-model="txt" size="mini" @focus="handleFocus">
              <template slot="append">
                <en-icon name="sousuo" color="#abb7c7" size="mini"></en-icon>
              </template>
            </el-input>
          </div>
          <ul>
            <li @click="handleKeySearch">包含 <span style="color: #3e90fe;">{{txt}}</span> 的邮件（全文搜索）</li>
            <li @click="handleAdvanceClick">高级搜索...</li>
          </ul>
        </el-popover>
      </div>
    </en-top-menu>
    <div class="mail-container">
      <div class="mail-list-wrap">
        <en-title-card name="搜索结果" style="overflow: hidden;">
          <template slot="right">
            <button-mod v-show="mailList.length" :mailType="0"></button-mod>
          </template>
        </en-title-card>
        <div class="mail-list-result">
          <en-result type="NoCustom" v-show="mailList.length === 0" subTitle="暂无搜索内容,换个搜索条件试试~" :imgPath="noDataImg"></en-result>
          <mail-mod v-for="(itm,index) in mailList" :key="itm.id" :index="index" :mailData="itm"
            :checkbox-visible="mailList.some(item => item.checked)" usedFlg="search"></mail-mod>
        </div>
        <en-pagination :pageModel="pageModel" @change="handlePageChanged" v-show="mailList.length"></en-pagination>
      </div>
    </div>
    <!-- 关联应用 -->
    <mapping-dialog ref="mappingDialog" :mapping-list="mappingList"
      @closeDialog="relatVisible = false"
      :relat-visible="relatVisible" @handleRelatClick="handleRelatClick"></mapping-dialog>
    <!-- 详情 -->
    <mail-detail
      v-if="detailVisible"
      :needNext="needNext"
      :nextObj="nextObj"
      ref="mailDetail"
      :id="detailId"
      :visible="detailVisible"
      @close="detailVisible = false"
    ></mail-detail>
    <!-- 高级搜索 -->
    <advance-search ref="advanceMod" :has-change-keyword="hasChangeKeyword"></advance-search>
    <!-- 新关联应用 -->
    <Mapping v-if="addMappingflg" :id="mappingIds" :show="addMappingflg" :mappingList="mappingList" @toShow="addMappingflg = false"></Mapping>
  </div>
</template>

<script>
import { mailBoxService } from "@/api/mailBox/mailList";
import mailDetail from "../MailDetail";
import mailBtnFunc from "../../mixins/mailBtnFunc";
import buttonMod from "../List/components/buttonMod";
import MappingDialog from "../List/components/mappingDialog";
import mailMod from "../List/components/mailMod";
import AdvanceSearch from "./components/AdvanceSearch";
import Mapping from "../MailDetail/mapping"; // 新的映射弹框

export default {
  name: "MailSearch",
  mixins: [mailBtnFunc],
  data() {
    return {
      txt: "", // 关键字
      enLoading: false,
      detailVisible: false,
      detailId: "",
      mailType: 0,
      noDataImg: "https://images.softfuxi.com/static/ccl.png", // 无数据图片 cdn
      mailList: [], // 邮件列表
      popShow: false,
      pageModel: { // 分页参数
        pageNo: 1,
        pageSize: 10,
        total: 0,
        totalPages: 0
      },
      hasChangeKeyword: false,
      seParams: { // 查询参数
        keywords: "",
        keywordsRange: "",
        sender: "",
        receiver: "",
        sendTimeRange: "",
        folderTypes: "",
        attachNum: "",
        readFlag: ""
      },
      // 新的 映射弹框
      addMappingflg: false,
      // 新的 映射弹框 当前页面选中的ids
      mappingIds: "",
      // 是否有翻页
      needNext: true,
      // 翻页数据
      nextObj: {}
    };
  },
  provide() {
    return {
      $mailList: this
    };
  },
  components: {
    mailDetail, mailMod, AdvanceSearch, buttonMod, MappingDialog, Mapping
  },
  watch: {
    txt(nVal) {
      this.popShow = !!nVal.trim();
      this.hasChangeKeyword = false;
    }
  },

  mounted() {
    // 查询映射数据
    this.initMappingList();
    this.$nextTick(() => {
      this.$refs.searchIpt.focus();
    }, 100);
  },
  methods: {
    /**
     * @description: 重置分页参数
     */
    resetPageModel() {
      Object.assign(this.$data.pageModel, this.$options.data().pageModel);
    },
    /**
     * @description: 查询邮件列表
     */
    async queryMailList() {
      try {
        this.enLoading = true;
        Object.assign(this.seParams, this.pageModel);
        const rsp = await mailBoxService.searchMail(this.seParams);
        this.enLoading = false;
        this.pageModel.total = rsp.total;
        this.pageModel.totalPages = rsp.totalPages;
        this.mailList = [...rsp.records];
        this.mailList.forEach((item) => {
          this.$set(item, "checked", false);
        });
      } catch (error) {
        this.enLoading = false;
      }
    },
    /**
     * @description: 按关键字 搜索
     */
    handleKeySearch() {
      this.popShow = false;
      this.resetPageModel();
      Object.assign(this.$data.seParams, this.$options.data().seParams);
      this.seParams.keywords = this.txt;
      this.hasChangeKeyword = true;
      this.queryMailList();
    },
    /**
     * @description: 高级搜索
     */
    handleAdvanceClick() {
      this.popShow = false;
      this.$refs.advanceMod.visible = true;
    },
    /**
     * @description: 输入框聚焦
     */
    handleFocus() {
      this.popShow = !!this.txt.trim();
    },
    /**
     * @description 分页器事件
     */
    handlePageChanged({ pageSize, pageNo }) {
      this.pageModel.pageNo = pageNo;
      this.pageModel.pageSize = pageSize;
      this.queryMailList();
    }

  }
};
</script>

<style lang='scss' scoped>
.mail-search-wrap{
  width: 100%;
  height: 100%;
  background: #ecf1f7;
  .en-top-menu{
    & /deep/ .en-top-menu-left{
      .en-icon{
        color: #a8b3c5;
      }
      .top-menu-name{
        font-size: 14px;
        margin-left: 5px;
      }
    }
    & /deep/ .en-top-menu-right{
      margin-right: 0;
    }
    .el-input{
      width: 400px;
    }
    & /deep/ .el-input-group__append{
      padding: 0 10px;
      background: #fff;
    }
  }
  .mail-container{
    height: calc(100% - 60px);
    padding: 10px;
  }
  .mail-list-wrap{
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 4px;
    .mail-list-result{
      height: calc(100% - 105px);
      overflow: hidden;
      overflow-y: auto;
      border-bottom: 1px solid #dce5ec;
      .en-result{
        margin-top: 200px;
      }
    }
  }
}
</style>
<style lang='scss'>
.mail-search-popover{
  margin-top: 0 !important;
  padding: 0;
  ul{
    margin: 0;
    padding: 0;
    li{
      font-size: 12px;
      padding: 10px;
      cursor: pointer;
      &:hover{
        background: #f5f8fc;
        color: #3e90fe;
      }
      &:first-child{
        border-bottom: 1px solid #e8ecf2;
      }
    }
  }
}
</style>
