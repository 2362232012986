var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      attrs: {
        visible: _vm.visible,
        width: "900px",
        title: "高级搜索",
        "close-on-press-escape": false,
      },
      on: {
        close: function ($event) {
          _vm.visible = false
        },
      },
    },
    [
      _c("div", { staticClass: "search-wrap" }, [
        _c("div", { staticClass: "search-item" }, [
          _c("label", { staticClass: "item-label" }, [_vm._v("关键字")]),
          _c(
            "div",
            { staticClass: "item-control" },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入关键字", maxlength: "100" },
                model: {
                  value: _vm.form.keywords,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "keywords", $$v)
                  },
                  expression: "form.keywords",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "search-item" }, [
          _c("label", { staticClass: "item-label" }),
          _c(
            "div",
            { staticClass: "item-control" },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.form.keywordsRange,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "keywordsRange", $$v)
                    },
                    expression: "form.keywordsRange",
                  },
                },
                [
                  _c("el-checkbox", { attrs: { label: "title" } }, [
                    _vm._v("主题"),
                  ]),
                  _c("el-checkbox", { attrs: { label: "content" } }, [
                    _vm._v("正文"),
                  ]),
                  _c("el-checkbox", { attrs: { label: "attachName" } }, [
                    _vm._v("附件"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "search-item" }, [
          _c("label", { staticClass: "item-label" }, [_vm._v("发件人")]),
          _c("div", { staticClass: "item-control" }, [
            _c(
              "div",
              { staticClass: "input-wrap sender-wrap" },
              [
                _c("input-tag", {
                  attrs: {
                    placeholder: "请输入发件人",
                    addTagOnKeys: [13, 188, 9],
                    value: _vm.form.sender,
                    validate: "",
                  },
                  model: {
                    value: _vm.form.sender,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "sender", $$v)
                    },
                    expression: "form.sender",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "search-item" }, [
          _c("label", { staticClass: "item-label" }, [_vm._v("收件人")]),
          _c("div", { staticClass: "item-control" }, [
            _c(
              "div",
              { staticClass: "input-wrap" },
              [
                _c("input-tag", {
                  attrs: {
                    placeholder: "请输入收件人",
                    addTagOnKeys: [13, 188, 9],
                    value: _vm.form.receiver,
                    validate: "",
                  },
                  model: {
                    value: _vm.form.receiver,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "receiver", $$v)
                    },
                    expression: "form.receiver",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "search-item" }, [
          _c("label", { staticClass: "item-label" }, [_vm._v("时间范围")]),
          _c(
            "div",
            { staticClass: "item-control" },
            [
              _c("en-select", {
                attrs: { data: _vm.timeOptions },
                on: { change: _vm.handleTimeTypeChange },
                model: {
                  value: _vm.form.sendTimeType,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sendTimeType", $$v)
                  },
                  expression: "form.sendTimeType",
                },
              }),
              _c("en-date-picker", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.form.sendTimeType === "2",
                    expression: "form.sendTimeType==='2'",
                  },
                ],
                attrs: {
                  "popper-class": "date-popper",
                  type: "datetimerange",
                  "range-separator": "——",
                  format: "yyyy-MM-dd HH:mm",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "start-placeholder": "开始时间",
                  "end-placeholder": "结束时间",
                },
                model: {
                  value: _vm.form.sendTimeRange,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sendTimeRange", $$v)
                  },
                  expression: "form.sendTimeRange",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "search-item" }, [
          _c("label", { staticClass: "item-label" }, [_vm._v("文件夹")]),
          _c(
            "div",
            { staticClass: "item-control" },
            [
              _c("en-select", {
                attrs: { data: _vm.folderOptions, multiple: false },
                model: {
                  value: _vm.form.folderTypes,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "folderTypes", $$v)
                  },
                  expression: "form.folderTypes",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "search-item" }, [
          _c("label", { staticClass: "item-label" }, [_vm._v("附件")]),
          _c(
            "div",
            { staticClass: "item-control" },
            [
              _c("select-mod", {
                attrs: { multiple: false, options: _vm.attachmentOptions },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "search-item" }, [
          _c("label", { staticClass: "item-label" }, [_vm._v("已读/未读")]),
          _c(
            "div",
            { staticClass: "item-control" },
            [
              _c("select-mod", {
                attrs: { multiple: false, options: _vm.readOptions },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("en-button", { on: { click: _vm.handleSubmit } }, [
            _vm._v("确定"),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }