<!--
 * @Author: pengyu
 * @Date: 2021-03-17 10:43:35
 * @LastEditors: pengyu
 * @LastEditTime: 2021-03-17 11:13:49
 * @Description: 高级筛选 选项块块
-->
<template>
  <div class="select-mod-wrap">
    <div
      :class="['item-mod', itm.active ? 'cur' : '']"
      v-for="(itm,idx) in options"
      :key="idx"
      @click="handleClick(itm.id)">
      <i class="el-icon-success"></i>
      <span>{{itm.name}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectModWrap",
  props: {
    options: { // 选项
      type: Array,
      default: () => []
    },
    multiple: { // 是否多选
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    };
  },
  methods: {
    /**
     * @description: 点击选项
     * @param {*} id
     */
    handleClick(id) {
      const obj = this.options.filter((itm) => itm.id === id)[0];
      if (!obj) return;
      if (this.multiple) { // 多选
        this.$set(obj, "active", !obj.active);
      } else { // 单选
        this.options.forEach((itm) => {
          this.$set(itm, "active", itm.id === id);
        });
      }
    }
  }
};
</script>

<style lang='scss' scoped>
.item-mod{
  width: 134px;
  height: 32px;
  border: 1px solid #e8ecf2;
  color: #636c78;
  cursor: pointer;
  background: #f5f8fc;
  text-align: center;
  line-height: 32px;
  border-radius: 4px;
  display: inline-block;
  margin-right: 10px;
  .el-icon-success{
    display: none;
    font-size: 14px;
    color: #26c393;
    margin-right: 5px;
  }
  &.cur{
    background: rgba($color: #26c393, $alpha: 0.1);
    .el-icon-success{
      display: inline-block;
    }
  }
}
</style>
