var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "select-mod-wrap" },
    _vm._l(_vm.options, function (itm, idx) {
      return _c(
        "div",
        {
          key: idx,
          class: ["item-mod", itm.active ? "cur" : ""],
          on: {
            click: function ($event) {
              return _vm.handleClick(itm.id)
            },
          },
        },
        [
          _c("i", { staticClass: "el-icon-success" }),
          _c("span", [_vm._v(_vm._s(itm.name))]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }