<!--
 * @Author: pengyu
 * @Date: 2021-03-16 14:01:26
 * @LastEditors: pengyu
 * @LastEditTime: 2021-04-19 17:47:12
 * @Description: 高级搜索弹框 v4.9.9
-->
<template>
  <en-dialog
    :visible="visible"
    width="900px"
    title="高级搜索"
    :close-on-press-escape="false"
    @close="visible=false"
  >
    <div class="search-wrap">
      <div class="search-item">
        <label class="item-label">关键字</label>
        <div class="item-control">
          <el-input v-model="form.keywords" placeholder="请输入关键字" maxlength="100"></el-input>
        </div>
      </div>
      <div class="search-item">
        <label class="item-label"></label>
        <div class="item-control">
          <el-checkbox-group v-model='form.keywordsRange'>
            <el-checkbox label="title">主题</el-checkbox>
            <el-checkbox label="content">正文</el-checkbox>
            <el-checkbox label="attachName">附件</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="search-item">
        <label class="item-label">发件人</label>
        <div class="item-control">
          <div class="input-wrap sender-wrap">
            <input-tag placeholder="请输入发件人" v-model="form.sender" :addTagOnKeys="[13, 188, 9]" :value="form.sender" validate=""></input-tag>
          </div>
        </div>
      </div>
      <div class="search-item">
        <label class="item-label">收件人</label>
        <div class="item-control">
          <div class="input-wrap">
            <input-tag placeholder="请输入收件人" v-model="form.receiver" :addTagOnKeys="[13, 188, 9]" :value="form.receiver" validate=""></input-tag>
          </div>
        </div>
      </div>
      <div class="search-item">
        <label class="item-label">时间范围</label>
        <div class="item-control">
          <en-select
            :data="timeOptions"
            v-model="form.sendTimeType"
            @change="handleTimeTypeChange"
          ></en-select>
          <en-date-picker
              popper-class="date-popper"
              v-show="form.sendTimeType==='2'"
              v-model="form.sendTimeRange"
              type="datetimerange"
              range-separator="——"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm:ss"
              start-placeholder="开始时间"
              end-placeholder="结束时间">
          </en-date-picker>
        </div>
      </div>
      <div class="search-item">
        <label class="item-label">文件夹</label>
        <div class="item-control">
          <en-select
            :data="folderOptions"
            v-model="form.folderTypes"
            :multiple="false"
          ></en-select>
        </div>
      </div>
      <div class="search-item">
        <label class="item-label">附件</label>
        <div class="item-control">
          <select-mod :multiple="false" :options="attachmentOptions"></select-mod>
        </div>
      </div>
      <div class="search-item">
        <label class="item-label">已读/未读</label>
        <div class="item-control">
          <select-mod :multiple="false" :options="readOptions"></select-mod>
        </div>
      </div>
    </div>

    <div slot="footer">
      <en-button @click="handleSubmit">确定</en-button>
    </div>
  </en-dialog>
</template>

<script>
import inputTag from "../../../../components/MailSend/InputTag/InputTag.vue";
import selectMod from "../SelectMod";

export default {
  name: "AdvanceSearch",
  props: {
    hasChangeKeyword: {
      type: Boolean,
      default: false
    }
  },
  components: { inputTag, selectMod },
  watch: {
    visible(nVal) {
      if (nVal && this.hasChangeKeyword) {
        Object.assign(this.$data, this.$options.data());
        this.form.keywords = this.$mailList.txt;
        this.visible = nVal;
      }
    }
  },
  inject: ["$mailList"],
  data() {
    return {
      visible: false,
      form: {
        keywords: "",
        keywordsRange: ["title", "content", "attachName"],
        sender: [],
        receiver: [],
        sendTimeRange: "",
        folderTypes: "0",
        attachNum: "",
        readFlag: "",
        sendTimeType: "1"
      },
      timeOptions: [
        { value: "1", label: "不限" },
        { value: "2", label: "自定义时间" }
      ],
      folderOptions: [
        { value: "0", label: "所有文件夹" },
        { value: "1", label: "收件箱" },
        { value: "2", label: "发件箱" },
        { value: "3", label: "草稿箱" },
        { value: "4", label: "已删除" },
        { value: "5", label: "垃圾箱" }
      ],
      attachmentOptions: [
        { id: "2", name: "不限", active: true },
        { id: "1", name: "含附件", active: false },
        { id: "0", name: "不含附件", active: false }
      ],
      readOptions: [
        { id: "2", name: "不限", active: true },
        { id: "1", name: "未读", active: false },
        { id: "0", name: "已读", active: false }
      ]
    };
  },
  methods: {
    /**
     * @description: 时间类型
     */
    handleTimeTypeChange(value) {
      if (Number(value) === 1) { // 不限
        this.form.sendTimeRange = "";
      }
    },
    /**
     * @description: 确定
     */
    handleSubmit() {
      const param = { ...this.form };
      param.keywordsRange = param.keywordsRange.join(",");
      const sd = param.sender.map((itm) => itm.mailAccount || itm.mailName);
      param.sender = sd.join(",");
      const rc = param.receiver.map((itm) => itm.mailAccount || itm.mailName);
      param.receiver = rc.join(",");
      const at = [];
      this.attachmentOptions.forEach((itm) => {
        if (itm.active && itm.id !== "2") {
          at.push(itm.id);
        }
      });
      param.attachNum = at.join(",");
      const ro = [];
      this.readOptions.forEach((itm) => {
        if (itm.active && itm.id !== "2") {
          ro.push(itm.id);
        }
      });
      param.readFlag = ro.join(",");
      if (param.sendTimeType === "2") { // 自定义时间
        param.sendTimeRange = param.sendTimeRange.join(",");
      }
      param.folderTypes = param.folderTypes.toString() === "0" ? "" : param.folderTypes;
      delete param.sendTimeType;

      this.$mailList.resetPageModel();
      Object.assign(this.$mailList.seParams, param);
      this.$mailList.queryMailList();
      this.$mailList.hasChangeKeyword = false;
      this.visible = false;
    }
  }
};
</script>

<style lang='scss' scoped>
.search-wrap{
  font-size: 12px;
}
.search-item{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .item-label{
    width: 80px;
    text-align: right;
    padding-right: 16px;
  }
  .el-checkbox{
    margin-right: 80px;
  }
  .item-control{
    flex: 1;
    display: flex;
    .en-select{
      width: 100%;
      flex: 1;
    }
    .el-date-editor{
      flex: 1;
      margin-left: 10px;
    }
  }
  .input-wrap{
    width: 100%;
    border: 1px solid #e8ecf2;
    border-radius: 4px;
    padding:0 10px;
    &:hover{
      border-color: #a2cdf7;
    }
    & /deep/ .vue-input-tag-wrapper{
      height: 32px;
      overflow: hidden;
      .new-tag{
        margin-bottom: 6px;
      }
    }
  }
  .sender-wrap {
    & /deep/ .vue-input-tag-wrapper{
      z-index: 5;
    }
  }
}
</style>
<style lang='scss'>
.date-popper{
  .el-button--default{
    background: #3e90fe !important;
    color: #ffffff !important;
  }
}
</style>
